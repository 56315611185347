export const paymentProcess = async (type, value, setAmount, amount, cart, tillData, selectedPaymentMethod, setOverPayedAmount, setNumb, numb) => {
  const calculateRoundingDifference = (value) => {
    // Round up the payment to the nearest multiple of 0.25
    const roundedPayment = Math.ceil(value / 0.25) * 0.25;

    // Calculate the raw overpayment (before considering change)
    const rawOverpayment = roundedPayment - Math.abs(cart.total - cart.paid);

    // Determine the maximum change that can be given
    const maxChange = Math.floor(rawOverpayment / 0.25) * 0.25;

    // Calculate the final overpayment, considering the maximum change
    const overpayment = rawOverpayment - maxChange - (roundedPayment - value);

    // Set the overpayment amount, ensuring two decimal places
    setOverPayedAmount(overpayment ? overpayment.toFixed(2) : 0);
  };

  if (type === "1") {
    if (tillData.tillAccess.cwrTill.layAway === "Y" && tillData.tillAccess.cwrTill.payNow === "N") {
      return null;
    }
    let cashTotal = cart.payments.reduce((total, item) => parseFloat(total) + parseFloat(item.amount), 0);

    if (amount === "" && value === "x") {
      setAmount("");
    } else if (value === "x") {
      setAmount(`${amount.toString().substring(0, amount.toString().length - 1)}`);
      if (cart.total <= parseFloat(`${amount.toString().substring(0, amount.toString().length - 1)}`) && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
        calculateRoundingDifference(parseFloat(`${amount.toString().substring(0, amount.toString().length - 1)}`));
      } else {
        setOverPayedAmount(0);
      }
    } else if (Object.keys(selectedPaymentMethod).length > 0) {
      if (numb === 0) {
        setNumb(numb + 1);
        setAmount(`${value}`);
        if (cart.total <= parseFloat(value) && selectedPaymentMethod.name.toLowerCase() === "cash") {
          calculateRoundingDifference(parseFloat(`${parseFloat(value)}`));
        } else {
          setOverPayedAmount(0);
        }
      } else {
        setAmount(`${amount}${value}`);
        let cashAmount = parseFloat(`${amount}${value}`);
        if (cart.total <= cashAmount && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
          calculateRoundingDifference(cashAmount);
        } else {
          setOverPayedAmount(0);
        }
      }
    }
  } else if (type === "2") {
    let cashTotal = cart.payments.reduce((total, item) => parseFloat(total) + parseFloat(item.amount), 0);

    if (numb === 0) {
      setNumb(numb + 1);
      setAmount(parseFloat(0) + parseFloat(value));
      if (cart.total - cart.paid <= value && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
        calculateRoundingDifference(parseFloat(0) + parseFloat(value));
      } else {
        setOverPayedAmount(0);
      }
    } else {
      setAmount(parseFloat(amount !== "" ? amount : 0) + parseFloat(value));
      if (cart.total - cart.paid <= parseFloat(value) + parseFloat(amount !== "" ? amount : 0) && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
        calculateRoundingDifference(parseFloat(amount !== "" ? amount : 0) + parseFloat(value));
      } else {
        setOverPayedAmount(0);
      }
    }
  } else if (type === "3") {
    let cashTotal = cart.payments.reduce((total, item) => parseFloat(total) + parseFloat(item.amount), 0);
    if (Object.keys(selectedPaymentMethod).length > 0) {
      if (cart.total <= parseFloat(value) + parseFloat(cashTotal) && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
        calculateRoundingDifference(parseFloat(value) + parseFloat(cashTotal));
      } else {
        setOverPayedAmount(0);
      }
      setAmount(value);
    }
  }
};
